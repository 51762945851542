<template>
  <b-form @submit="onSubmit">
    <b-row class="spacing">
      <b-col sm="4" class="labelContainer">
        <label class="spacing">TC Kimlik No</label>
      </b-col>
      <b-col sm="8">
        <b-form-input id="input-2" v-model="form.ssn" type="number" required />
      </b-col>
    </b-row>

    <b-row class="spacing">
      <VueRecaptcha
        ref="recaptcha"
        @verify="onCaptchaVerified"
        @expired="onCaptchaExpired"
        size="invisible"
        sitekey="6Ldz3QgfAAAAAAy-TksDqe7AaViHD9AUrh7R0-A5"
      />
    </b-row>
    <b-form-row class="center spacing">
      <b-col>
        <b-button type="reset" variant="danger">İptal</b-button>
      </b-col>
      <b-col>
        <b-button type="submit" variant="success">Gönder</b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>
<script>
import { VueRecaptcha } from "vue-recaptcha";
export default {
  name: "PasswordPage",
  components: { VueRecaptcha },
  data() {
    return {
      form: {
        ssn: "",
      },
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.$refs.recaptcha.execute();
    },
    onCaptchaVerified(recaptchaToken) {
      this.form.recaptchaToken = recaptchaToken;
      this.$refs.recaptcha.reset();
      this.$store.dispatch("forgotPassword", this.form).then(() => {
        this.$router.push("/");
      });
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
  },
};
</script>


<style scoped>
html,
body {
  background-color: black;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.labelContainer {
  display: grid;
  place-items: center;
}
.center {
  text-align: center;
}
.spacing {
  margin-top: 0.5rem;
}
</style>